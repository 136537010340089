import React, { useEffect, useState } from "react"

import { every, intersection, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { Icon, Menu } from "semantic-ui-react"

import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

import { getPayload } from "../../../store/selectors/requests/requestsSelectors"
import { getUserToken } from "../../../store/selectors/sessionStorage/userSelectors"
import { getBeneficiaryServiceUrl, getBeneficiaryUrl } from "../../actions/temporaries/environment/environmentActions"
import { menuContentType } from "../../constants/menuContentType"
import { RequestInfo, RequestKeys } from "../../constants/requests"
import colors from "../../styles/_default.colors.module.scss"
import { redirectionEbUniqueEbCesuVersEbSap, redirectionEbUniqueEbSapVersEbCesu } from "../../utils/redirectionEbUnique"
import ResponsiveLogo, { RESPONSIVE_LOGO_VARIANTS } from "../ResponsiveLogo/responsiveLogo"

import "./sideMenu.scss"

const SideMenuEbUnique = ({
    actualPage,
    autoCloseAccordions,
    cookiesChoice,
    darkBackground,
    isEbUnique,
    items,
    getMainBeneficiaireId,
    logoBackgroundColor,
    onClickLogo,
    pagesArchitecture,
    requestKey,
    srcLogo,
    textColor,
    userPermissions,
    withFaIcons,
    fromEBS,
    contractId,
    visualisationMode
}) => {
    const history = useHistory()

    const dispatch = useDispatch()
    const isConnected = useSelector(state => getUserToken(state))

    const [accordionStates, setAccordionStates] = useState({})

    const textStyle = { color: textColor }
    const urlEnCours = window.location.href

    const urlEBCesu = useSelector(state =>
        getPayload(
            state,
            fromEBS ? requestKey.GET_ESPACE_BENEFICIARY_URL : RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_URL]?.name,
        ),
    )
    const urlEBService = useSelector(state =>
        getPayload(
            state,
            fromEBS
                ? requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL
                : RequestInfo[requestKey.GET_ESPACE_BENEFICIARY_SERVICE_URL]?.name,
        ),
    )

    const openOrClose = (item, forceOpen) => {
        setAccordionStates(prevState => ({
            ...prevState,
            [item.page]: forceOpen !== undefined ? forceOpen : !prevState[item.page],
        }))
    }

    const redirectUnique = (item, PagesArchitecture) => {
        let needRedirection = true

        if (isEbUnique) {
            // Cas redirection CESU vers SAP
            if (urlEnCours.includes(urlEBCesu) === true && item.isSAP === true) {
                needRedirection = false
                redirectionEbUniqueEbCesuVersEbSap(contractId, visualisationMode, cookiesChoice, dispatch, item.page, urlEBService)
            }

            // Cas Redirection SAP vers CESU
            if (urlEnCours.includes(urlEBService) === true && !(item.isSAP === true)) {
                needRedirection = false
                redirectionEbUniqueEbSapVersEbCesu(dispatch, item.page, urlEBCesu)
            }

            if (needRedirection) {
                return history.push(pagesArchitecture[item.page].route)
            }
        }
    }

    // On passe sur tous les sous menus des accordéons pour les ouvrir si on est sur une des pages le rendant actif/ouvert
    useEffect(() => {
        items
            .filter(item => item.type === menuContentType.ACCORDION)
            .forEach(item => {
                if (item.activeOnPages.includes(actualPage)) {
                    openOrClose(item, true)
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items])

    // Si autoCloseAccordions est vrai, alors on ferme automatiquement les menus si nous ne sommes plus sur une des pages le rendant actif/ouvert
    useEffect(() => {
        if (autoCloseAccordions) {
            items
                .filter(item => item.type === menuContentType.ACCORDION)
                .forEach(item => {
                    if (!item.activeOnPages.includes(actualPage)) {
                        openOrClose(item, false)
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, actualPage])

    useEffect(() => {
        if (isConnected) {
            dispatch(getBeneficiaryServiceUrl(fromEBS, RequestKeys))
            dispatch(getBeneficiaryUrl(fromEBS, RequestKeys))
        }
    }, [dispatch, isConnected, fromEBS])

    const isHiddenItem = item =>
        item.hidden === true ||
        (item.type === menuContentType.ACCORDION && every(item.subItems, item => item.hidden === true))

    return (
        <Menu
            attached="top"
            className={"side-menu " + (darkBackground ? "dark-background" : "light-background")}
            pointing
            secondary
            style={textStyle}
            vertical
        >
            {srcLogo && (
                <div className="side-menu-header" style={{ backgroundColor: logoBackgroundColor }}>
                    <ResponsiveLogo onClick={onClickLogo} src={srcLogo} variant={RESPONSIVE_LOGO_VARIANTS.HEADER} />
                </div>
            )}
            {!isEmpty(items) &&
                items?.map(item => {
                    if (isHiddenItem(item)) {
                        return undefined
                    }

                    if (item.type === menuContentType.SIMPLE) {
                        return createMenuItem(
                            actualPage,
                            history,
                            pagesArchitecture,
                            item,
                            false,
                            userPermissions,
                            false,
                            redirectUnique,
                            isEbUnique,
                            withFaIcons,
                        )
                    }

                    if (item.type === menuContentType.CATEGORY && item.name) {
                        return (
                            <Menu.Item header key={"menu-header-" + item.name}>
                                {item.name && item.name.toUpperCase()}
                                {item.icon &&
                                    (withFaIcons ? <Icon className={item.icon} /> : <Icon name={item.icon} />)}
                            </Menu.Item>
                        )
                    }

                    if (item.type === menuContentType.ACCORDION) {
                        return (
                            <>
                                <Menu.Item
                                    className={
                                        item.activeOnPages.includes(actualPage)
                                            ? "side-menu-accordion side-menu-accordion-active"
                                            : null
                                    }
                                    key={`item-${item.page}-${accordionStates[item.page]}`}
                                    onClick={() => openOrClose(item)}
                                >
                                    {item.icon &&
                                        (withFaIcons ? (
                                            <Icon className={`${item.icon} side-menu-icon`} />
                                        ) : (
                                            <Icon className="side-menu-icon" name={item.icon} size="large" />
                                        ))}
                                    <span>{item.name}</span>
                                    {withFaIcons ? (
                                        <Icon
                                            className={`${accordionStates[item.page]
                                                ? "fa-regular fa-angle-up"
                                                : "fa-regular fa-angle-down"
                                                } side-menu-accordion-icon`}
                                        />
                                    ) : (
                                        <Icon
                                            className="side-menu-accordion-icon"
                                            name={accordionStates[item.page] ? "arrow up" : "arrow down"}
                                            size="large"
                                        />
                                    )}
                                </Menu.Item>

                                {accordionStates[item.page] &&
                                    item.subItems.map(subItem =>
                                        createMenuItem(
                                            actualPage,
                                            history,
                                            pagesArchitecture,
                                            subItem,
                                            false,
                                            userPermissions,
                                            true,
                                            redirectUnique,
                                            isEbUnique,
                                            withFaIcons,
                                        ),
                                    )}
                            </>
                        )
                    }

                    return ""
                })}
        </Menu>
    )
}

export const createMenuItem = (
    actualPage,
    history,
    pagesArchitecture,
    item,
    disabled,
    userPermissions,
    isSubItem,
    redirectUnique,
    isEbUnique,
    withFaIcons = false,
) => {
    const name = pagesArchitecture[item.page]?.breadcrumbText
    if (
        (pagesArchitecture[item.page]?.permissions &&
            intersection(pagesArchitecture[item.page]?.permissions, userPermissions).length === 0) ||
        item.hidden === true
    ) {
        return undefined
    }

    return (
        <Menu.Item
            active={actualPage === item.page || item.activeOnPages?.includes(actualPage)}
            className={isSubItem ? "side-menu-main-menu-sub-item" : ""}
            disabled={disabled}
            key={`menu-item-${name}`}
            onClick={() => (isEbUnique ? redirectUnique(item, pagesArchitecture) : null)}
        >
            {item.icon &&
                (withFaIcons ? (
                    <Icon className={`${item.icon} side-menu-icon`} />
                ) : (
                    <Icon className="side-menu-icon" name={item.icon} size="large" />
                ))}
            <span>{name}</span>
        </Menu.Item>
    )
}

SideMenuEbUnique.propTypes = {
    actualPage: PropTypes.string.isRequired,
    autoCloseAccordions: PropTypes.bool,
    darkBackground: PropTypes.bool,
    isEbUnique: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object),
    getMainBeneficiaireId: PropTypes.any.isRequired,
    logoBackgroundColor: PropTypes.string,
    onClickLogo: PropTypes.func.isRequired,
    pagesArchitecture: PropTypes.object.isRequired,
    requestKey: PropTypes.object.isRequired,
    srcLogo: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    userPermissions: PropTypes.array,
    redirectUnique: PropTypes.func.isRequired,
    withFaIcons: PropTypes.bool, // TODO A supprimer quand les packages Font Awesome Pro seront integrés dans tous les espaces
    fromEBS: PropTypes.bool.isRequired,
}

SideMenuEbUnique.defaultProps = {
    autoCloseAccordions: false,
    darkBackground: false,
    isEbUnique: false,
    items: [],
    logoBackgroundColor: null,
    textColor: colors.white,
    userPermissions: null,
    withFaIcons: false,
}

export default SideMenuEbUnique
