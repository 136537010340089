import React from "react"

import PropTypes from "prop-types"
import {Image} from "semantic-ui-react"

import "./responsiveLogo.scss"

export const RESPONSIVE_LOGO_VARIANTS = {DEFAULT: "default", HEADER: "header"}

const ResponsiveLogo = ({fixedWidth, src, variant, style, ...props}) => {
    return (
        <div className={`responsive-logo variant-${variant}${fixedWidth ? " fixed-width" : ""}`} style={style}>
            <Image alt="logo" src={src} {...props} />
        </div>
    )
}

ResponsiveLogo.propTypes = {
    fixedWidth: PropTypes.bool,
    src: PropTypes.string.isRequired,
    style: PropTypes.object,
    variant: PropTypes.oneOf(Object.values(RESPONSIVE_LOGO_VARIANTS)),
}

ResponsiveLogo.defaultProps = {
    fixedWidth: true,
    style: undefined,
    variant: RESPONSIVE_LOGO_VARIANTS.DEFAULT,
}

export default ResponsiveLogo
