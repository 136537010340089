import {isEmpty} from "lodash"
import PropTypes from "prop-types"
import React from "react"
import {Grid, Icon, Modal as SemanticUIModal} from "semantic-ui-react"
import "./modal.scss"
import ButtonPrimary from "../ButtonPrimary/buttonPrimary"
import ButtonSecondary from "../ButtonSecondary/buttonSecondary"

const Modal = ({
    actionsContent,
    closeButtonDisabled,
    closeButtonIsLoading,
    closeFunction,
    closeLabel,
    confirmButtonDisabled,
    confirmButtonIsLoading,
    confirmFunction,
    confirmLabel,
    content,
    contentScrolling,
    contentSize,
    isOpen,
    rightCloseLabel,
    title,
    ...props
}) => {
    return (
        <SemanticUIModal className={`modal ${contentSize}-content`} onClose={closeFunction} open={isOpen} {...props}>
            <SemanticUIModal.Header className="modal-header">
                <Grid>
                    <Grid.Row columns="2">
                        <Grid.Column width={14}>{title}</Grid.Column>
                        <Grid.Column width={2}>
                            <div onClick={closeFunction}>
                                <Icon className="fa-solid fa-xmark" />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </SemanticUIModal.Header>
            <SemanticUIModal.Content className="modal-content" scrolling={contentScrolling}>
                {content}
            </SemanticUIModal.Content>
            <SemanticUIModal.Actions className="modal-actions">
                {/* Contenu personnalisé du footer */}
                {!!actionsContent && actionsContent}

                {/* Contenu par défaut */}
                {isEmpty(actionsContent) && (
                    <>
                        {closeLabel && (
                            <ButtonSecondary
                                disabled={closeButtonDisabled}
                                loading={closeButtonIsLoading}
                                onClick={closeFunction}
                                size={contentSize}
                            >
                                {closeLabel}
                            </ButtonSecondary>
                        )}
                        {confirmLabel && (
                            <ButtonPrimary
                                disabled={confirmButtonDisabled}
                                loading={confirmButtonIsLoading}
                                onClick={confirmFunction}
                                size={contentSize}
                            >
                                {confirmLabel}
                            </ButtonPrimary>
                        )}
                        {rightCloseLabel && (
                            <ButtonSecondary
                                disabled={closeButtonDisabled}
                                loading={closeButtonIsLoading}
                                onClick={closeFunction}
                                size={contentSize}
                            >
                                {rightCloseLabel}
                            </ButtonSecondary>
                        )}
                    </>
                )}
            </SemanticUIModal.Actions>
        </SemanticUIModal>
    )
}

Modal.propTypes = {
    actionsContent: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]),
    closeButtonDisabled: PropTypes.bool,
    closeButtonIsLoading: PropTypes.bool,
    closeFunction: PropTypes.func.isRequired,
    closeLabel: PropTypes.string,
    confirmButtonDisabled: PropTypes.bool,
    confirmButtonIsLoading: PropTypes.bool,
    confirmFunction: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.elementType]).isRequired,
    contentScrolling: PropTypes.bool,
    contentSize: PropTypes.oneOf(["small", "medium"]),
    isOpen: PropTypes.bool.isRequired,
    rightCloseLabel: PropTypes.string,
    title: PropTypes.string,
}

Modal.defaultProps = {
    actionsContent: null,
    closeButtonIsLoading: false,
    closeButtonDisabled: false,
    closeLabel: null,
    confirmButtonDisabled: false,
    confirmButtonIsLoading: false,
    confirmLabel: null,
    contentScrolling: true,
    contentSize: "medium",
    rightCloseLabel: null,
    title: "",
}

export default Modal
