import {combineReducers} from "redux"
import style, * as fromStyle from "../../shared-components/store/localStorage/style/styleReducer"
import texts, * as fromTexts from "../../shared-components/store/localStorage/texts/textsReducer"
import dynamicConfiguration, * as fromDynConf from "../../shared-components/store/localStorage/dynamicConfiguration/dynamicConfigurationReducer"
import orderProfile, * as fromOrderProfile from "./orderProfile/orderProfileReducer"
import orderDelivery, * as fromOrderDelivery from "./orderDelivery/orderDeliveryReducer"
import orderContent, * as fromOrderContent from "./orderContent/orderContentReducer"
import orderPayment, * as fromOrderPayment from "./orderPayment/orderPaymentReducer"
import orderType, * as fromOrderType from "./orderType/orderTypeReducer"
import orderConfirmation, * as fromOrderConfirmation from "./orderConfirmation/orderConfirmationReducer"
import preOrderSession, * as fromPreOrderSession from "./preOrderSession/preOrderSessionReducer"
import contracts, * as fromContracts from "./contracts/contractsReducer"
import cookies, * as fromCookies from "../../shared-components/store/localStorage/cookies/cookiesReducer"
import features, * as fromFeatures from "../../shared-components/store/localStorage/features/featuresReducer"
import visualisation, * as fromVisualisation from "./visualisation/visualisationReducer"
import cesuConversionChoice, * as fromCesuConversionChoice from "./cesuConversionChoice/cesuConversionChoiceReducer"
import cesuConversionAmount, * as fromCesuConversionAmount from "./cesuConversionAmount/cesuConversionAmountReducer"
import cesuConversionDelivery, * as fromCesuConversionDelivery from "./cesuConversionDelivery/cesuConversionDeliveryReducer"
import cesuConversionConfirmation, * as fromCesuConversionConfirmation from "./cesuConversionConfirmation/cesuConversionConfirmationReducer"
import accountPaymentChoice, * as fromAccountPaymentChoice from "./accountPaymentChoice/accountPaymentChoiceReducer"
import accountPaymentOrganization, * as fromAccountPaymentOrganization from "./accountPaymentOrganization/accountPaymentOrganizationReducer"
import accountPaymentCesuIdentification, * as fromAccountPaymentCesuIdentification from "./accountPaymentCesuIdentification/accountPaymentCesuIdentificationReducer"
import accountPaymentCesuDeclaration, * as fromAccountPaymentCesuDeclaration from "./accountPaymentCesuDeclaration/accountPaymentCesuDeclarationReducer"
import accountPaymentPajeIdentification, * as fromAccountPaymentPajeIdentification from "./accountPaymentPajeIdentification/accountPaymentPajeIdentificationReducer"
import accountPaymentPajeDeclaration, * as fromAccountPaymentPajeDeclaration from "./accountPaymentPajeDeclaration/accountPaymentPajeDeclarationReducer"
import accountPaymentAmount, * as fromAccountPaymentAmount from "./accountPaymentAmount/accountPaymentAmountReducer"
import accountPaymentConfirmation, * as fromAccountPaymentConfirmation from "./accountPaymentConfirmation/accountPaymentConfirmationReducer"
import checkPaymentChoice, * as fromCheckPaymentChoice from "./checkPaymentChoice/checkPaymentChoiceReducer"
import checkPaymentAmount, * as fromCheckPaymentAmount from "./checkPaymentAmount/checkPaymentAmountReducer"
import checkPaymentConfirmation, * as fromCheckPaymentConfirmation from "./checkPaymentConfirmation/checkPaymentConfirmationReducer"
import registrationSessionChoice, * as fromRegistrationSessionChoice from "./registrationSessionChoice/registrationSessionChoiceReducer"
import registrationControl, * as fromRegistrationControl from "./registrationControl/registrationControlReducer"
import registrationProfile, * as fromRegistrationProfile from "./registrationProfile/registrationProfileReducer"
import registrationConfirmation, * as fromRegistrationConfirmation from "./registrationConfirmation/registrationConfirmationReducer"
import millesimeTradeChoice, * as fromMillesimeTradeChoice from "./millesimeTradeChoice/millesimeTradeChoiceReducer"
import millesimeTradeDelivery, * as fromMillesimeTradeDelivery from "./millesimeTradeDelivery/millesimeTradeDeliveryReducer"
import millesimeTradeConfirmation, * as fromMillesimeTradeConfirmation from "./millesimeTradeConfirmation/millesimeTradeConfirmationReducer"

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const localStorage = combineReducers({
    accountPaymentChoice,
    accountPaymentOrganization,
    accountPaymentCesuIdentification,
    accountPaymentCesuDeclaration,
    accountPaymentPajeIdentification,
    accountPaymentPajeDeclaration,
    accountPaymentAmount,
    accountPaymentConfirmation,
    cesuConversionChoice,
    cesuConversionAmount,
    cesuConversionDelivery,
    cesuConversionConfirmation,
    checkPaymentChoice,
    checkPaymentAmount,
    checkPaymentConfirmation,
    contracts,
    cookies,
    dynamicConfiguration,
    features,
    millesimeTradeChoice,
    millesimeTradeDelivery,
    millesimeTradeConfirmation,
    orderConfirmation: orderConfirmation,
    orderContent,
    orderDelivery: orderDelivery,
    orderPayment: orderPayment,
    orderProfile: orderProfile(),
    orderType,
    preOrderSession,
    registrationConfirmation,
    registrationControl,
    registrationProfile,
    registrationSessionChoice,
    style,
    texts,
    visualisation,
})

export default localStorage

// DYNAMIC CONFIGURATION OPERATIONS
/**
 * Return the value associated with the key if it is found in the state,
 * undefined otherwise.
 */
export const getDynamicConfigurationValue = (state, key) => fromDynConf.getValue(state.dynamicConfiguration, key)

// STYLE OPERATIONS
/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromStyle.getStyle(state.style, contractId)
/**
 * Is style customized ?
 */
export const isCustomizedStyle = (state, contractId) => fromStyle.isCustomized(state.style, contractId)

// TEXTS OPERATIONS
/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromTexts.getTexts(state.texts, contractId)
/**
 * Are texts customized ?
 */
export const areCustomizedTexts = (state, contractId) => fromTexts.areCustomized(state.texts, contractId)

// ORDER OPERATIONS
export const getOrderConfirmation = state => fromOrderConfirmation.getOrderConfirmation(state.orderConfirmation)

export const getOrderContent = state => fromOrderContent.getOrderContent(state.orderContent)

export const getOrderDelivery = state => fromOrderDelivery.getOrderDelivery(state.orderDelivery)

export const getOrderPayment = state => fromOrderPayment.getOrderPayment(state.orderPayment)

export const getOrderProfile = state => fromOrderProfile.getOrderProfile(state.orderProfile)

export const getOrderType = state => fromOrderType.getOrderType(state.orderType)

// PRE ORDER SESSION OPERATIONS
export const getPreOrderSession = state => fromPreOrderSession.getPreOrderSession(state.preOrderSession)

// CONTRACTS OPERATIONS
export const getContracts = state => fromContracts.getContracts(state.contracts)

// COOKIES OPERATIONS
/**
 * Get cookies
 */
export const getCookiesChoices = state => fromCookies.getCookiesChoices(state.cookies)

// FEATURES OPERATIONS
/**
 * Get enabled features
 */
export const getEnabledFeatures = state => fromFeatures.getEnabledFeatures(state.features)

// VISUALISATION OPERATIONS

/**
 * Return user selected visualisation mode
 */
export const getVisualisationMode = state => fromVisualisation.getVisualisationMode(state.visualisation)
/**
 * Return user selected contract
 */
export const getSelectedContract = state => fromVisualisation.getSelectedContract(state.visualisation)

// CESU CONVERSION OPERATIONS

export const getProductChoice = state => fromCesuConversionChoice.getProductChoice(state.cesuConversionChoice)

export const getCesuConversionAmount = state =>
    fromCesuConversionAmount.getCesuConversionAmount(state.cesuConversionAmount)

export const getCesuConversionDelivery = state =>
    fromCesuConversionDelivery.getCesuConversionDelivery(state.cesuConversionDelivery)

export const getCesuConversionConfirmation = state =>
    fromCesuConversionConfirmation.getCesuConversionConfirmation(state.cesuConversionConfirmation)

// ACCOUNT PAYMENT OPERATIONS

export const getAccountPaymentAmount = state =>
    fromAccountPaymentAmount.getAccountPaymentAmount(state.accountPaymentAmount)

export const getAccountPaymentOrganization = state =>
    fromAccountPaymentOrganization.getAccountPaymentOrganization(state.accountPaymentOrganization)

export const getAccountPaymentCesuIdentification = state =>
    fromAccountPaymentCesuIdentification.getAccountPaymentCesuIdentification(state.accountPaymentCesuIdentification)

export const getAccountPaymentCesuDeclaration = state =>
    fromAccountPaymentCesuDeclaration.getAccountPaymentCesuDeclaration(state.accountPaymentCesuDeclaration)

export const getAccountPaymentPajeIdentification = state =>
    fromAccountPaymentPajeIdentification.getAccountPaymentPajeIdentification(state.accountPaymentPajeIdentification)

export const getAccountPaymentPajeDeclaration = state =>
    fromAccountPaymentPajeDeclaration.getAccountPaymentPajeDeclaration(state.accountPaymentPajeDeclaration)
export const getAccountPaymentChoice = state =>
    fromAccountPaymentChoice.getAccountPaymentChoice(state.accountPaymentChoice)

export const getAccountPaymentConfirmation = state =>
    fromAccountPaymentConfirmation.getAccountPaymentConfirmation(state.accountPaymentConfirmation)

// CHECK PAYMENT OPERATIONS

export const getCheckPaymentAmount = state => fromCheckPaymentAmount.getCheckPaymentAmount(state.checkPaymentAmount)

export const getCheckPaymentChoice = state => fromCheckPaymentChoice.getCheckPaymentChoice(state.checkPaymentChoice)

export const getCheckPaymentConfirmation = state =>
    fromCheckPaymentConfirmation.getCheckPaymentConfirmation(state.checkPaymentConfirmation)

// REGISTRATION OPERATIONS

export const getRegistrationSessionChoice = state =>
    fromRegistrationSessionChoice.getRegistrationSessionChoice(state.registrationSessionChoice)

export const getRegistrationControl = state => fromRegistrationControl.getRegistrationControl(state.registrationControl)

export const getRegistrationProfile = state => fromRegistrationProfile.getRegistrationProfile(state.registrationProfile)

export const getRegistrationConfirmation = state =>
    fromRegistrationConfirmation.getRegistrationConfirmation(state.registrationConfirmation)

// MILLESIME TRADE OPERATIONS
export const getMillesimeTradeChoice = state =>
    fromMillesimeTradeChoice.getMillesimeTradeChoice(state.millesimeTradeChoice)
export const getMillesimeTradeDelivery = state =>
    fromMillesimeTradeDelivery.getMillesimeTradeDelivery(state.millesimeTradeDelivery)

export const getMillesimeTradeConfirmation = state =>
    fromMillesimeTradeConfirmation.getMillesimeTradeConfirmation(state.millesimeTradeConfirmation)
