import React, {useEffect} from "react"

import PropTypes from "prop-types"
import {useLocation} from "react-router-dom"

import {useIsResponsive} from "../../hooks/useIsResponsive"
import {useTexts} from "../../hooks/useTexts"
import Accordion from "../Accordion/Accordion"
import ButtonPrimary from "../ButtonPrimary/buttonPrimary"
import Modal from "../Modal/modal"

import "./style.scss"

const CookiesModal = ({isOpen, onClose}) => {
    const texts = useTexts()
    const isResponsive = useIsResponsive()
    const {search} = useLocation()

    const params = new URLSearchParams(search.substring(1))
    const cookiesEnabled = params.get("cookiesEnabled")

    // Si le paramètre cookiesEnabled est défini dans l'url, on accepte automatiquement les cookies
    useEffect(() => {
        if (cookiesEnabled) {
            onClose()
        }
    }, [cookiesEnabled, onClose])

    return (
        <Modal
            closeFunction={onClose}
            closeLabel={texts.closeButton}
            content={
                <div className="cookies-modal">
                    <p>
                        {texts.cookiesModalParagraph1}
                        <br />
                        {texts.cookiesModalParagraph2}
                    </p>
                    <p className="informations-link">
                        <a href={texts.cookiesModalInformationsLinkValue} target="cnil">
                            {texts.cookiesModalInformationsLink}
                        </a>
                    </p>
                    <Accordion
                        fluid
                        rightComponent={
                            <ButtonPrimary disabled icon="fa-solid fa-check" iconPosition="left" primary size="small">
                                {texts.cookiesModalRequiredButton}
                            </ButtonPrimary>
                        }
                    >
                        <div title={texts.cookiesModalAccordionTitle}>
                            <p>{texts.cookiesModalParagraph3}</p>
                            <p>{texts.cookiesModalParagraph4}</p>
                            {isResponsive && (
                                <ButtonPrimary
                                    disabled
                                    icon="fa-solid fa-check"
                                    iconPosition="left"
                                    primary
                                    size="small"
                                >
                                    {texts.cookiesModalRequiredButton}
                                </ButtonPrimary>
                            )}
                        </div>
                    </Accordion>
                </div>
            }
            isOpen={isOpen}
            title={texts.cookiesModalTitle}
        />
    )
}

CookiesModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
}

CookiesModal.defaultProps = {
    isOpen: false,
    onClose: null,
}

export default CookiesModal
