import React, {useEffect, useMemo, useState} from "react"

import PropTypes from "prop-types"
import {Grid, Icon, Accordion as SemanticAccordion} from "semantic-ui-react"

import colors from "../../styles/_default.colors.module.scss"

import "./accordion.scss"

const Accordion = ({
    children,
    fixed,
    headerBackgroundColor,
    openedOnMount,
    onClick,
    sticked,
    borderless,
    rightComponent,
    ...rest
}) => {
    const [activePanel, setActivePanel] = useState()

    // Ouvre l'accordéon seulement au montage du composant
    useEffect(() => {
        if (openedOnMount) {
            setActivePanel(0)
        }
    }, [openedOnMount])

    const panels = useMemo(
        () =>
            React.Children.map(React.Children.toArray(children).filter(Boolean), (child, index) => {
                const handleClick = (_, titleProps) => {
                    if (!fixed) {
                        const {index} = titleProps
                        const newIndex = activePanel === index ? -1 : index
                        setActivePanel(newIndex)

                        if (!!onClick) {
                            onClick()
                        }
                    }
                }

                return (
                    <Grid className={sticked ? "sticked" : ""} columns={1}>
                        <Grid.Column>
                            <SemanticAccordion className={borderless ? "borderless" : ""} {...rest} styled>
                                <SemanticAccordion.Title
                                    active={activePanel === index}
                                    index={index}
                                    onClick={handleClick}
                                    style={{backgroundColor: headerBackgroundColor}}
                                >
                                    {!fixed && <Icon className="fa-solid fa-caret-down" />}
                                    <span>{child.props.title}</span>
                                    {rightComponent && <div className="right-component">{rightComponent}</div>}
                                </SemanticAccordion.Title>
                                <SemanticAccordion.Content active={activePanel === index}>
                                    {child}
                                </SemanticAccordion.Content>
                            </SemanticAccordion>
                        </Grid.Column>
                    </Grid>
                )
            }),
        [children, sticked, borderless, rest, activePanel, headerBackgroundColor, fixed, onClick, rightComponent],
    )

    return panels
}

Accordion.propTypes = {
    borderless: PropTypes.bool,
    fixed: PropTypes.bool,
    headerBackgroundColor: PropTypes.string,
    onClick: PropTypes.func,
    openedOnMount: PropTypes.bool,
    rightComponent: PropTypes.node,
    sticked: PropTypes.bool,
}

Accordion.defaultProps = {
    borderless: false,
    fixed: false,
    headerBackgroundColor: colors.darkBlue50,
    rightComponent: null,
    sticked: false,
}

export default Accordion
