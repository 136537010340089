import React, { Component } from "react"

import dayjs from "dayjs"
import { startsWith } from "lodash"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Route, Switch, withRouter } from "react-router-dom"

import { Order } from "../constants/orderActionsEnum"
import { Page } from "../constants/page/pages"
import { PagesArchitecture } from "../constants/page/pagesArchitecture"
import { saveCookiesChoices } from "../shared-components/actions/localStorage/cookies/cookiesActions"
import { disconnectUser } from "../shared-components/actions/sessionStorage/user/userActions"
import { setResponsive } from "../shared-components/actions/temporaries/menu/menuAction"
import { logoutUser } from "../shared-components/common/actions/user/userActions"
import CheckBrowserContainer from "../shared-components/components/CheckBrowser/checkBrowserContainer"
import CookiesModal from "../shared-components/components/CookiesModal"
import NotificationsContainer from "../shared-components/components/Notifications/notificationsContainer"
import { DOMISERVE_CONTACT_URL } from "../shared-components/constants/links"
import NotificationsLayout from "../shared-components/layouts/notificationsLayout"
import { wasAuthenticationBypassed } from "../store/selectors/localStorage/bypassedAuthenticationSelectors"
import { showCookiesModal } from "../store/selectors/localStorage/cookiesSelectors"
import { getOrderType } from "../store/selectors/localStorage/orderTypeSelectors"
import { getVisualisationMode } from "../store/selectors/localStorage/visualisationSelectors"
import { getNotifications } from "../store/selectors/notifications/notificationsSelectors"
import { isFromBackOffice, isReadOnly, isUserConnected } from "../store/selectors/sessionStorage/userSelectors"
import { getActualPage } from "../store/selectors/temporaries/pageSelectors"
import { MatomoTrackPageView } from "./matomoTrackPageView"
import RoutingLayout from "./routingLayout"
class MainLayout extends Component {
    componentDidMount() {
        this.checkVisualisationMode(this.props.actualPage)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.actualPage !== this.props.actualPage) {
            this.checkVisualisationMode(nextProps.actualPage)
            this.checkBackOffice(nextProps.actualPage)
            this.checkBypassLogin(nextProps.actualPage)
            this.checkReadOnly(nextProps.actualPage)
        }
    }

    /**
     * Visualisation mode check
     */
    checkVisualisationMode = actualPage => {
        // Check if user is logged in without bypassing login and has not selected any visualisation mode
        if (this.props.isUserConnected && !this.props.wasAuthenticationBypassed && !this.props.visualisationMode) {
            // Force the user to go to VISUALISATION_CHOICE page
            // This is mandatory because the user could access any pages by URL otherwise
            this.props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route)
        }

        if (this.props.isUserConnected && this.props.visualisationMode) {
            const visualisationModes = PagesArchitecture[actualPage].visualisationModes
            if (visualisationModes && !visualisationModes.includes(this.props.visualisationMode)) {
                this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
            }
        }
    }

    checkBackOffice = actualPage => {
        if (
            this.props.isFromBackOffice &&
            !startsWith(actualPage, "MILLESIME") &&
            !startsWith(actualPage, "VISUALISATION_CHOICE") &&
            !startsWith(actualPage, "HOME")
        ) {
            this.props.history.push(PagesArchitecture[Page.MILLESIME_TRADE_CHOICE].route)
        }
    }

    checkBypassLogin = actualPage => {
        // If authentication was bypassed, we're not from back office and not from tutor access (readOnly === undefined) and we're not on ORDER page or LEGAL NOTICE page
        if (
            this.props.wasAuthenticationBypassed &&
            !this.props.isFromBackOffice &&
            this.props.isReadOnly === undefined &&
            !startsWith(actualPage, "ORDER") &&
            !startsWith(actualPage, "LEGAL_NOTICE")
        ) {
            // If order type is VIEW ORDER we redirect to concerned page
            if (this.props.orderType === Order.VIEW_ORDER) {
                this.props.history.push(PagesArchitecture[Page.VIEW_ORDER].route)
            } else {
                this.props.history.push(PagesArchitecture[Page.ORDER_INFORMATION].route)
            }
        }
    }

    checkReadOnly = actualPage => {
        const hiddenInReadOnlyMode = PagesArchitecture[actualPage].hiddenInReadOnlyMode
        if (hiddenInReadOnlyMode && this.props.isReadOnly) {
            this.props.history.push(PagesArchitecture[Page.UNAUTHORIZED].route)
        }
    }

    onDisconnectClick = () => {
        this.props.logoutUser()
        this.props.disconnectUser()
    }

    render() {
        const { actualPage, isCookiesModalOpened, onCookiesClose } = this.props

        return (
            <>
                <CheckBrowserContainer userAgent={window.navigator.userAgent} />
                <MatomoTrackPageView />

                <NotificationsLayout>
                    <NotificationsContainer
                        displayNotifications={actualPage !== Page.LOGIN}
                        getNotifications={getNotifications}
                    />
                </NotificationsLayout>

                <Switch>
                    <Route actualPage={actualPage} component={RoutingLayout} path="" />
                </Switch>

                <CookiesModal isOpen={isCookiesModalOpened} onClose={onCookiesClose} />
            </>
        )
    }
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    onCookiesClose: PropTypes.func.isRequired,
    onContactClick: PropTypes.func.isRequired,
    onDisconnectClick: PropTypes.func.isRequired,
    isCookiesModalOpened: PropTypes.bool.isRequired,
}

const mapStateToProps = state => {
    let actualPage = getActualPage(state)
    return {
        actualPage: actualPage,
        isCookiesModalOpened: showCookiesModal(state),
        isFromBackOffice: isFromBackOffice(state),
        isReadOnly: isReadOnly(state),
        isUserConnected: isUserConnected(state) && actualPage !== Page.BYPASS_LOGIN,
        orderType: getOrderType(state),
        visualisationMode: getVisualisationMode(state),
        wasAuthenticationBypassed: wasAuthenticationBypassed(state),
    }
}

const mapDispatchToProps = (dispatch, props) => ({
    // Action lors de la fermeture de la modale des cookies
    onCookiesClose: () =>
        dispatch(
            saveCookiesChoices({
                isModalOpened: false,
                expirationDate: dayjs().add(6, "month"),
                enabled: true,
            }),
        ),

    // Go to the contact page.
    onContactClick: () => window.open(DOMISERVE_CONTACT_URL),

    // Disconnect the current user.
    disconnectUser: () => dispatch(disconnectUser()),
    logoutUser: () => dispatch(logoutUser()),

    // Go to the home page.
    onHomeClick: () => {
        props.history.push(PagesArchitecture[Page.HOME].route)
    },
    // Go to the legal mentions page.
    onLegalClick: () => props.history.push(PagesArchitecture[Page.LEGAL_NOTICE].route),

    // Go to the rgpd page.
    onRgpdClick: () => props.history.push(PagesArchitecture[Page.RGPD].route),

    // Go to user profile page
    onProfileClick: () => props.history.push(PagesArchitecture[Page.USER_PROFILE_MAIN_INFORMATIONS].route),

    // Go to visualisation choice page
    onVisualisationChoiceClick: () => props.history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route),

    setMenuResponsive: isResponsive => {
        dispatch(setResponsive(isResponsive))
    },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainLayout))
