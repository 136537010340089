import {clearDynamicConfiguration} from "../actions/localStorage/dynamicConfiguration/dynamicConfigurationActions"
import {getBypassTokenSap, getBypassTokenSidomi, logoutUser} from "../common/actions/user/userActions"
import {removeTokenFromHeader} from "../services/configureAxios"

/**
 * Méthodes de redirection entre les deux espaces bénéficiaires dans le cadre de l'EB unique
 * - Récupération du token d'authentification auprès de l'autre API
 * - Supression des données de connexion en local
 * - Redirection
 * - Déconnexion
 */

export function redirectionEbUniqueEbSapVersEbCesu(dispatch, page, urlEspace) {
    dispatch(getBypassTokenSidomi()).then(data => {
        // Supression token
        dispatch(clearDynamicConfiguration())
        removeTokenFromHeader()

        window.open(
            `${urlEspace}/bypass-login-from-sap?userId=${data.identifiantCible}&bypassToken=${data.jetonConnexion}&iv=${data.iv}&pages=${page}`,
            "_self",
        )

        // Invalidité du token
        dispatch(logoutUser())
    })
}

export function redirectionEbUniqueEbCesuVersEbSap(contractId, contractType, cookiesChoice, dispatch, page, urlEspace) {
    dispatch(getBypassTokenSap(contractId, contractType)).then(data => {
        // Supression token
        dispatch(clearDynamicConfiguration())
        removeTokenFromHeader()

        window.open(
            `${urlEspace}/bypass-login?userId=${data.identifiantCible}&clientId=${data.clientCible}&bypassToken=${data.jetonConnexion}&iv=${data.iv}&pages=${page}&cookiesEnabled=${cookiesChoice}`,
            "_self",
        )

        // Invalidité du token
        dispatch(logoutUser())
    })
}
