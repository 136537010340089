import React from "react"
import { Loader } from "semantic-ui-react"
import "./fullScreenLoader.scss"

export const FullScreenLoader = () => {
    return (
        <div className="loader-overlay">
            <Loader active />
        </div>
    )
}
