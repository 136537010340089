import {menuContentType} from "../../../constants/menuContentType"
import {ModesVisualisation} from "../../../constants/modesVisualisation"
import {Page} from "./pages"

/**
 * Factory method to create a menu item
 * @param actualPage page where the user is
 * @param history    history function for navigation
 * @param features   enabled application features
 * @param page       page where the item should redirect
 * @param pagesArchitecture the application pages architecture
 * @param text       override the text
 * @param onClick    override the onClick function
 * @param isFeatureEnabled    override isFeatureEnabled
 */
export const createItem = (
    actualPage,
    history,
    features,
    page,
    pagesArchitecture,
    text,
    onClick,
    isFeatureEnabled,
) => ({
    type: "ITEM",
    text: text ? text : pagesArchitecture[page].breadcrumbText,
    active: actualPage === page,
    onClick: onClick ? () => onClick() : () => history.push(pagesArchitecture[page].route),
    userConnected: pagesArchitecture[page].userConnected,
    permissions: pagesArchitecture[page].permissions,
    isFeatureEnabled: isFeatureEnabled
        ? isFeatureEnabled
        : !pagesArchitecture[page].featureFlag ||
          (pagesArchitecture[page].featureFlag && features.includes(pagesArchitecture[page].featureFlag)),
    feature: pagesArchitecture[page].featureFlag,
})

/**
 * Factory method to create a menu item that opens a new web page.
 * @param actualPage page where the user is
 * @param history    history function for navigation
 * @param features   enabled application features
 * @param page       page where the item should redirect
 * @param pagesArchitecture the application pages architecture
 * @param text       override the text
 * @param isFeatureEnabled    override isFeatureEnabled
 */
export const createLink = (actualPage, history, features, page, pagesArchitecture, text, isFeatureEnabled) => {
    // Fetch configuration for the page from pagesArchitecture
    const pageConfig = pagesArchitecture[page]

    // Prepare the onClick handler that opens the link in a new tab
    const handleLinkClick = event => {
        window.open(pageConfig.route, "_blank", "noopener,noreferrer")
    }

    return {
        type: "ITEM",
        text: text || pageConfig.breadcrumbText, // Use custom text or fallback to breadcrumb text
        active: actualPage === page,
        onClick: handleLinkClick, // Use the custom handleLinkClick function
        userConnected: pageConfig.userConnected,
        permissions: pageConfig.permissions,
        isFeatureEnabled:
            !pageConfig.featureFlag || (pageConfig.featureFlag && features.includes(pageConfig.featureFlag)),
        feature: pageConfig.featureFlag,
    }
}

/**
 * Factory method to create a DropDown
 * @param dropdownText Text to show on the DropDown
 * @param isUserConnected show the drop down only when the user is connected
 * @param dropdownChoices all drop down choices
 * @param alignment LEFT or RIGHT
 */
export const createDropdown = (dropdownText, isUserConnected, dropdownChoices, alignment) => {
    return {
        type: "DROPDOWN",
        text: dropdownText,
        userConnected: isUserConnected,
        choices: dropdownChoices,
        alignment,
    }
}

const isFeatureEnabled = (page, readOnly, features, pagesArchitecture) =>
    (!pagesArchitecture[page]?.featureFlag ||
        (pagesArchitecture[page]?.featureFlag && features?.includes(pagesArchitecture[page]?.featureFlag))) &&
    (!pagesArchitecture[page]?.hiddenInReadOnlyMode || !(pagesArchitecture[page]?.hiddenInReadOnlyMode && readOnly))

/**
 * Retourne la liste d'items du menu en fonction du mode de visualisation: CESU (SOCIAL ou RH), SAP, Unique (CESU + SAP)
 */
export const menuContent = ({
    features,
    fromBackOffice,
    isContractWithHoldingAllowed,
    isEbUnique,
    isEbSap,
    pagesArchitecture,
    readOnly,
    texts,
    visualisationMode,
    withBypassedAuthentication,
    fromEBS,
}) => {
    // Items commun du menu
    const homepageItem = {
        type: menuContentType.SIMPLE,
        page: Page.HOME,
        icon: "fa-regular fa-house",
    }

    const orderSessionListItem = {
        type: menuContentType.SIMPLE,
        page: Page.ORDER_SESSION_LIST,
        activeOnPages: [
            Page.ORDER_INFORMATION,
            Page.ORDER_CONTENT,
            Page.ORDER_CONFIRMATION,
            Page.ORDER_DELIVERY,
            Page.ORDER_PAYMENT,
            Page.ORDER_PROFILE,
        ],
    }

    const millesimeTradeChoiceItem = {
        type: menuContentType.SIMPLE,
        page: Page.MILLESIME_TRADE_CHOICE,
        activeOnPages: [Page.MILLESIME_TRADE_CONFIRMATION, Page.MILLESIME_TRADE_DELIVERY, Page.MILLESIME_TRADE_PAYMENT],
    }

    const cesuConversionChoiceItem = {
        type: menuContentType.SIMPLE,
        page: Page.CESU_CONVERSION_CHOICE,
        activeOnPages: [Page.CESU_CONVERSION_AMOUNT, Page.CESU_CONVERSION_CONFIRMATION, Page.CESU_CONVERSION_DELIVERY],
    }

    const orderHistoryItem = {
        type: menuContentType.SIMPLE,
        page: Page.ORDER_HISTORY,
        activeOnPages: [],
    }

    const accountStatementItem = {
        type: menuContentType.SIMPLE,
        page: Page.ACCOUNT_STATEMENT,
        activeOnPages: [],
    }

    const accountPaymentChoiceItem = {
        type: menuContentType.SIMPLE,
        page: Page.ACCOUNT_PAYMENT_CHOICE,
        activeOnPages: [
            Page.ACCOUNT_PAYMENT_AMOUNT_OVERVIEW,
            Page.ACCOUNT_PAYMENT_AMOUNT,
            Page.ACCOUNT_PAYMENT_CESU_DECLARATION,
            Page.ACCOUNT_PAYMENT_CESU_IDENTIFICATION,
            Page.ACCOUNT_PAYMENT_CONFIRMATION,
            Page.ACCOUNT_PAYMENT_ORGANIZATION,
            Page.ACCOUNT_PAYMENT_PAJE_DECLARATION,
            Page.ACCOUNT_PAYMENT_PAJE_IDENTIFICATION,
        ],
    }

    const accountPaymentHistoryItem = {
        type: menuContentType.SIMPLE,
        page: Page.ACCOUNT_PAYMENT_HISTORY,
        activeOnPages: [],
    }

    const declarationHistoryItem = {
        type: menuContentType.SIMPLE,
        page: Page.DECLARATION_HISTORY,
        activeOnPages: [],
    }

    const checkPaymentChoiceItem = {
        type: menuContentType.SIMPLE,
        page: Page.CHECK_PAYMENT_CHOICE,
        activeOnPages: [Page.CHECK_PAYMENT_AMOUNT, Page.CHECK_PAYMENT_CONFIRMATION],
    }

    const checkPaymentHistoryItem = {
        type: menuContentType.SIMPLE,
        page: Page.CHECK_PAYMENT_HISTORY,
        activeOnPages: [],
    }

    const commandeChoixDispositifItem = {
        type: menuContentType.SIMPLE,
        page: Page.COMMANDE_CHOIX_DISPOSITIF,
        isSAP: true,
        activeOnPages: [
            Page.COMMANDE_ATTRIBUTION,
            Page.COMMANDE_CHOIX_DISPOSITIF,
            Page.COMMANDE_CONFIRMATION,
            Page.COMMANDE_INFORMATIONS_COMPLEMENTAIRES,
            Page.COMMANDE_PRESTATION,
        ],
    }

    const commandeSuiviItem = {
        type: menuContentType.SIMPLE,
        page: Page.COMMANDE_SUIVI,
        isSAP: true,
        activeOnPages: [Page.COMMANDE_VISUALISATION_MODIFICATION],
    }

    const taxDocumentItem = {
        type: menuContentType.SIMPLE,
        page: Page.TAX_DOCUMENT,
        activeOnPages: [],
    }

    // MENU EN MODE EB UNIQUE
    if (isEbUnique) {
        return [
            homepageItem,

            //MENU COMMANDE
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-cart-shopping",
                isCesu: true,
                name: texts.menuCommandesTitre,
                page: Page.ORDER_SESSION_LIST,
                subItems: [
                    {
                        ...orderSessionListItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ORDER_SESSION_LIST, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...millesimeTradeChoiceItem,
                        hidden: !isFeatureEnabled(Page.MILLESIME_TRADE_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...cesuConversionChoiceItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CESU_CONVERSION_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...orderHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ORDER_HISTORY, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [
                    orderSessionListItem.page,
                    ...orderSessionListItem.activeOnPages,
                    millesimeTradeChoiceItem.page,
                    ...millesimeTradeChoiceItem.activeOnPages,
                    cesuConversionChoiceItem.page,
                    ...cesuConversionChoiceItem.activeOnPages,
                    orderHistoryItem.page,
                    ...orderHistoryItem.activeOnPages,
                ],
            },
            {
                //MENU E-CESU
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-laptop-mobile",
                isCesu: true,
                name: texts.menuECesuTitre,
                page: Page.ACCOUNT_STATEMENT,
                subItems: [
                    {
                        ...accountStatementItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ACCOUNT_STATEMENT, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...accountPaymentChoiceItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...accountPaymentHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ACCOUNT_PAYMENT_HISTORY, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...declarationHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isContractWithHoldingAllowed ||
                            !isFeatureEnabled(Page.DECLARATION_HISTORY, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [
                    accountStatementItem.page,
                    ...accountStatementItem.activeOnPages,
                    accountPaymentChoiceItem.page,
                    ...accountPaymentChoiceItem.activeOnPages,
                    accountPaymentHistoryItem.page,
                    ...accountPaymentHistoryItem.activeOnPages,
                    declarationHistoryItem.page,
                    ...declarationHistoryItem.activeOnPages,
                ],
            },
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-money-check-pen",
                isCesu: true,
                name: texts.menuCesuPapierTitre,
                page: Page.CHECKBOOK_STATEMENT,
                subItems: [
                    {
                        ...checkPaymentChoiceItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CHECK_PAYMENT_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...checkPaymentHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CHECK_PAYMENT_HISTORY, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [
                    checkPaymentChoiceItem.page,
                    ...checkPaymentChoiceItem.activeOnPages,
                    checkPaymentHistoryItem.page,
                    ...checkPaymentHistoryItem.activeOnPages,
                ],
            },
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-person-shelter",
                name: texts.menuPrestationsTitre,
                page: Page.COMMANDE,
                isSAP: true,
                subItems: [commandeChoixDispositifItem, commandeSuiviItem],
                activeOnPages: [
                    commandeChoixDispositifItem.page,
                    ...commandeChoixDispositifItem.activeOnPages,
                    commandeSuiviItem.page,
                    ...commandeSuiviItem.activeOnPages,
                ],
            },
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-file",
                name: texts.menuDocumentsTitre,
                page: Page.TAX_DOCUMENT,
                subItems: [
                    {
                        ...taxDocumentItem,
                        hidden: !isFeatureEnabled(Page.TAX_DOCUMENT, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [taxDocumentItem.page, ...taxDocumentItem.activeOnPages],
            },
        ]
    }

    // MENU EN MODE CESU RH
    if (visualisationMode === ModesVisualisation.CESU_RH) {
        return [
            homepageItem,
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-cart-shopping",
                name: texts.menuNavMyCesuOrders,
                page: Page.ORDER_SESSION_LIST,
                subItems: [
                    {
                        ...orderSessionListItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ORDER_SESSION_LIST, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...millesimeTradeChoiceItem,
                        hidden: !isFeatureEnabled(Page.MILLESIME_TRADE_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...cesuConversionChoiceItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CESU_CONVERSION_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...orderHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ORDER_HISTORY, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [
                    orderSessionListItem.page,
                    ...orderSessionListItem.activeOnPages,
                    millesimeTradeChoiceItem.page,
                    ...millesimeTradeChoiceItem.activeOnPages,
                    cesuConversionChoiceItem.page,
                    ...cesuConversionChoiceItem.activeOnPages,
                    orderHistoryItem.page,
                    ...orderHistoryItem.activeOnPages,
                ],
            },
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-laptop-mobile",
                name: texts.menuNavMyECesu,
                page: Page.ACCOUNT_STATEMENT,
                subItems: [
                    {
                        ...accountStatementItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ACCOUNT_STATEMENT, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...accountPaymentChoiceItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...accountPaymentHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.ACCOUNT_PAYMENT_HISTORY, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...declarationHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isContractWithHoldingAllowed ||
                            !isFeatureEnabled(Page.DECLARATION_HISTORY, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [
                    accountStatementItem.page,
                    ...accountStatementItem.activeOnPages,
                    accountPaymentChoiceItem.page,
                    ...accountPaymentChoiceItem.activeOnPages,
                    accountPaymentHistoryItem.page,
                    ...accountPaymentHistoryItem.activeOnPages,
                    declarationHistoryItem.page,
                    ...declarationHistoryItem.activeOnPages,
                ],
            },
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-money-check-pen",
                name: texts.menuNavMyPaperCesu,
                page: Page.CHECKBOOK_STATEMENT,
                subItems: [
                    {
                        type: menuContentType.SIMPLE,
                        page: Page.CHECKBOOK_STATEMENT,
                        activeOnPages: [],
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CHECKBOOK_STATEMENT, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...checkPaymentChoiceItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CHECK_PAYMENT_CHOICE, readOnly, features, pagesArchitecture),
                    },
                    {
                        ...checkPaymentHistoryItem,
                        hidden:
                            fromBackOffice ||
                            !isFeatureEnabled(Page.CHECK_PAYMENT_HISTORY, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [
                    Page.CHECKBOOK_STATEMENT,
                    checkPaymentChoiceItem.page,
                    ...checkPaymentChoiceItem.activeOnPages,
                    checkPaymentHistoryItem.page,
                    ...checkPaymentHistoryItem.activeOnPages,
                ],
            },
            {
                type: menuContentType.ACCORDION,
                icon: "fa-regular fa-file",
                name: texts.menuNavMyDocuments,
                page: Page.TAX_DOCUMENT,
                subItems: [
                    {
                        ...taxDocumentItem,
                        hidden: !isFeatureEnabled(Page.TAX_DOCUMENT, readOnly, features, pagesArchitecture),
                    },
                ],
                activeOnPages: [taxDocumentItem.page, ...taxDocumentItem.activeOnPages],
            },
        ]
    }

    // MENU EN MODE CESU SOCIAL
    if (visualisationMode === ModesVisualisation.CESU_SOCIAL) {
        return [
            homepageItem,
            {
                ...accountPaymentChoiceItem,
                icon: "fa-regular fa-euro-sign",
                hidden: !isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, readOnly, features, pagesArchitecture),
            },
            {
                page: Page.TRANSACTIONS_HISTORY,
                type: menuContentType.SIMPLE,
                icon: "fa-regular fa-timer",
                activeOnPages: [],
                hidden: !isFeatureEnabled(Page.TRANSACTIONS_HISTORY, readOnly, features, pagesArchitecture),
            },
            {
                ...declarationHistoryItem,
                icon: "fa-regular fa-timer",
                hidden:
                    !isContractWithHoldingAllowed ||
                    !isFeatureEnabled(Page.DECLARATION_HISTORY, readOnly, features, pagesArchitecture),
            },
        ]
    }
}
