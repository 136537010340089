import React, {useMemo} from "react"

import PropTypes from "prop-types"
import {useTheme} from "react-jss"
import {useDispatch, useSelector} from "react-redux"

import {LOGO} from "../constants/imageDefault"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import Footer from "../shared-components/components/FooterNew/footerNew"
import Header from "../shared-components/components/Header/header"
import LightHeader from "../shared-components/components/LightHeader/lightHeader"
import ResponsiveLogo from "../shared-components/components/ResponsiveLogo/responsiveLogo"
import {BENEFICIARY_AREA} from "../shared-components/constants/areaTypes"
import {DOMISERVE_CONTACT_URL} from "../shared-components/constants/links"
import {useIsResponsive} from "../shared-components/hooks/useIsResponsive"
import {useTexts} from "../shared-components/hooks/useTexts"
import ContentLayout from "../shared-components/layouts/contentLayout"
import FixedLayout from "../shared-components/layouts/fixedLayout"
import {getImageFromRemotePath} from "../shared-components/services/filer"
import shadows from "../shared-components/styles/_default.shadows.module.scss"
import {isContractEbUnique} from "../store/selectors/localStorage/contractsSelectors"
import {getSelectedContract} from "../store/selectors/localStorage/visualisationSelectors"
import {isEbUnique} from "../store/selectors/sessionStorage/userSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import colors from "../styles/_colors.module.scss"
import sizes from "../styles/_sizes.module.scss"

const DisconnectedLayout = ({children}) => {
    const dispatch = useDispatch()
    const isResponsive = useIsResponsive()
    const texts = useTexts()
    const theme = useTheme()

    const actualPage = useSelector(state => getActualPage(state))
    const contractId = useSelector(state => getSelectedContract(state))
    const compteEbUnique = useSelector(state => isEbUnique(state))
    const contratEbUnique = useSelector(state => isContractEbUnique(state, contractId))
    const ebUnique = compteEbUnique && contratEbUnique

    const personnalisationLogo = useMemo(() => getImageFromRemotePath(texts.logo, BENEFICIARY_AREA, LOGO), [texts.logo])
    const customColor = useMemo(
        () => (texts.loginFooter && theme.primaryColor ? theme.primaryColor : null),
        [texts.loginFooter, theme.primaryColor],
    )

    const onContactClick = () => window.open(DOMISERVE_CONTACT_URL)
    const onCookiesClick = () => dispatch(saveCookiesChoices({isModalOpened: true}))

    // Les éléments du footer (pages non connectées)
    const footerItems = [
        {
            label: PagesArchitecture[Page.RGPD].breadcrumbText,
            page: Page.RGPD,
            route: PagesArchitecture[Page.RGPD].route,
        },
        {
            label: texts.cookiesFooterLabel,
            onClick: onCookiesClick,
            page: null,
            route: PagesArchitecture[actualPage].route,
        },
        {
            label: texts.contactFooterLabel,
            onClick: onContactClick,
            page: null,
            route: PagesArchitecture[actualPage].route,
        },
    ]

    return (
        <>
            <FixedLayout>
                <LightHeader
                    backgroundColor={customColor || colors.lightBlue400}
                    height={isResponsive ? sizes.headerColorMobileHeight : sizes.headerColorHeight}
                    style={{zIndex: 300}}
                />
                <LightHeader
                    backgroundColor={colors.white}
                    centerItem={
                        isResponsive ? <ResponsiveLogo src={personnalisationLogo} /> : <h1>{texts.headerTitle}</h1>
                    }
                    height={isResponsive ? sizes.headerSecondaryMobileHeight : sizes.headerSecondaryHeight}
                    leftItem={
                        isResponsive ? null : (
                            <ResponsiveLogo
                                src={personnalisationLogo}
                                style={{
                                    marginLeft: sizes.spacing09,
                                }}
                            />
                        )
                    }
                    style={{boxShadow: shadows.shadowHeader}}
                />
                {isResponsive && (
                    <Header
                        backgroundColor={colors.darkBlue50}
                        textColor={colors.darkBlue800}
                        title={texts.headerTitle}
                    />
                )}
            </FixedLayout>
            <ContentLayout
                footer={<Footer backgroundColor={customColor || colors.footerBackgroundColor} items={footerItems} />}
                left={sizes.spacing00}
                top={isResponsive ? sizes.headerDisconnectedMobileTotalHeight : sizes.headerDisconnectedTotalHeight}
            >
                {children}
            </ContentLayout>
        </>
    )
}

DisconnectedLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default DisconnectedLayout
